<router-outlet></router-outlet>
<div class="overlay" id="loading">
    <div class="loader"></div>
</div>

<!-- Swiper -->
<div class="header">
    <div class="logo f-left">
        <img src="./assets/img/logo.svg" alt="" onclick="logoClk()">
    </div>
    <div class="language">
        <div class="en" onclick="langEn()">EN</div>
        <div class="tr" onclick="langTr()">TR</div>
    </div>
    <div class="f-right header-right-text" onclick="story()">OUR STORY</div>

</div>
<div class="footer">
    <div class="f-left footer-left-text"  (click)="exp()">
        EXPLORE
    </div>
    <div class="f-right footer-right-text" onclick="contact()">CONTACT US</div>
</div>
<div class="swiper-container swiper-container-v">

    <div class="swiper-wrapper">

        <div class="swiper-slide bg-gradient-00 0">
            <div class="container">
                <div class="gradient hide"></div>
                <video width="320" height="240" id="vid" #videoPlayer autoplay loop muted playsinline hidden>
                    <source src="https://mobilecenterprod.blob.core.windows.net/uploads/SJiIEL_Q5.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>

                <video width="320" height="240" id="vidEn" #videoPlayer autoplay loop muted playsinline>
                    <source src="https://mobilecenterprod.blob.core.windows.net/uploads/ryy1XS1m9.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>

                
                <div id="unMuted" class="unmuted" onclick="toggleUnmute()">
                </div>
                <div id="Muted" class="muted" onclick="toggleMute()" hidden>
                </div>
            </div>
        </div>

        <div class="swiper-slide bg-gradient-01 1">
            <div class="container">
                <div class="gradient"></div>
                <div class="bg-image"></div>
                <div class="text-area">
                    <div class="title">Game Changer</div>
                    <div class="sub-title">The most innovative solution to inner logistics</div>
                    <div class="content">With this product, we do the good picking, material handling
                        work and we provide the most cost efficient vehicle on market
                        that is just the fit for your point to point transportation,
                        increasing the efficiency and productivity, saving time.</div>
                    <div class="dis-more" onclick="nextPage(2)">Discover More</div>
                </div>
            </div>
        </div>

        <div class="swiper-slide bg-gradient-02 2">
            <div class="container">
                <div class="gradient"></div>
                <div class="bg-image"></div>
                <div class="text-area">
                    <div class="title">Born out of Need</div>
                    <div class="sub-title">Co-creation of a corporate and a startup</div>
                    <div class="content">Wamo is a vehicle co-created by a startup and a corporate
                        which directly serves to real world needs. Wamo is functional,
                        ergonomic, durable, respectful to the nature and affordable.
                        We are touching so many lives with this disruptive product.</div>
                    <div class="dis-more" onclick="nextPage(3)">Discover More</div>
                </div>
            </div>
        </div>

        <div class="swiper-slide bg-gradient-03 3">
            <div class="container">
                <div class="gradient"></div>
                <div class="bg-image"></div>
                <div class="text-area">
                    <div class="title">Ride. Pick. Repeat.</div>
                    <div class="sub-title">Simplest way of material handling</div>
                    <div class="content">This product is very easy to use and user friendly. Your workers
                        will love this vehicle and they will have so much fun riding it
                        that they will forget that they are working. Whatever, you
                        almost do not need any training to get started with Wamo.</div>
                    <div class="dis-more" onclick="nextPage(4)">Discover More</div>
                </div>
            </div>
        </div>

        <div class="swiper-slide bg-gradient-04 4">
            <div class="container">
                <div class="gradient"></div>
                <div class="bg-image"></div>
                <div class="text-area">
                    <div class="title">Approved by Migros</div>
                    <div class="sub-title">Reaching up to 90% efficiency levels</div>
                    <div class="content">There can be many use cases that this vehicle can be adapted
                        to such as distribution centers, indoor logistics, warehouses,
                        factories, large premises, airports, hotels, malls. Contact us for
                        further propositions. New variations are on the way.</div>
                    <div class="dis-more" onclick="nextPage(5)">Discover More</div>
                </div>
            </div>
        </div>

        <div class="swiper-slide bg-gradient-05 5">
            <div class="container">
                <div class="gradient"></div>
                <div class="bg-image"></div>
                <div class="text-area">
                    <div class="title">Sustainable</div>
                    <div class="sub-title">Green, sustainable indoor mobility</div>
                    <div class="content">With the electric hub motor and the one move swappable battery
                        system is the most sustainable option, due to its lower costs in
                        the market. It increases morale through the operation, decrease
                        inequalities of very kind and a big force for climate action.</div>
                    <div class="dis-more" onclick="nextPage(6)">Discover More</div>
                </div>
            </div>
        </div>

        <div class="swiper-slide bg-gradient-06 6">
            <div class="container">
                <div class="gradient"></div>
                <div class="bg-image"></div>
                <div class="text-area">
                    <div class="title">Co-innovation</div>
                    <div class="sub-title">Our story begins with co-innovation</div>
                    <div class="content" style="max-width: 500px;">This is the story of Migros Ticaret A.Ş., the pioneer of retail in Turkey
                        and Hergele Mobility, an emerging startup, joining forces in order to
                        revolutionize the mobility of inner logistics, by co-creating a bold,
                        strong, sustainable vehicle that is also cost efficient and beyond its
                        time, with the greatest impact at the right fit.</div>
                    <div class="dis-more" onclick="nextPage(7)">Discover More</div>
                </div>
            </div>
        </div>

        <div class="swiper-slide bg-gradient-07 7">
            <div class="container">
                <div class="gradient"></div>
                <div class="bg-image"></div>
                <div class="text-area">
                    <div class="title">Contact</div>
                    <div class="sub-title">We will be happy to help.</div>
                    <div class="content">Wamo vehicles are currently being utilized in Migros distribution
                        centers. Second half of 2022, Wamo vehicles will be open for preorders. For further information,
                        subscribe to our monthly newsletter
                        and join the community.</div>
                </div>
                <div class="form-area">
                    <div class="form-title">Get in touch with us!</div>
                    <input type="text" placeholder="Name" id="mail-name">
                    <input type="text" placeholder="Email" id="mail-email">
                    <textarea name="" cols="30" rows="10" placeholder="Message" id="mail-content"></textarea>
                    <div class="submit" onclick="email()">Submit</div>

                </div>
                <div class="footer-area">
                    Wamo is co-owned by Migros Ticaret AŞ and Hergele Mobility
                </div>
            </div>
        </div>

        <div class="swiper-pagination"></div>
    </div>
</div>