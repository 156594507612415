import { Component ,OnInit} from '@angular/core';

declare var name: any;
declare var explore: any;
declare var swiper: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {



  ngOnInit(){
    new swiper()
    new name()
  }


  exp(){
    new explore()
  }
}
